function refreshMap(clusterId, callback) {
    var mapUrl = "/reservations/map"

    $("#reservation-map").html('<object data="' + mapUrl + "/" + clusterId + '" type="image/svg+xml" id="svgmap" width="100%" height="100%"></object>')
    document.getElementById("svgmap").addEventListener("load", function () {
        var svgDoc = document.getElementById("svgmap").contentDocument
        var viewBox = $("svg", svgDoc).attr("viewBox")
        var width = Number(viewBox.split(" ")[3])
        var height = Number(viewBox.split(" ")[2])
        var ratio = Math.ceil((width / height) * 100) + 2 + "%"

        $("#reservation-map-container").css("padding-top", ratio)
        callback()
    })
}

function switchToDeskReservationForm() {
    $("#desk-reservation-input").show()
    $("#room-reservation-input").hide()
    console.log("TEST")
}

function refreshRoomAvailabilityDetails() {
    $("#availability-details").html(
        '<div class="text-center" style="flex: 12 0 0;"><div class="spinner-border spinner-border-sm m-1" role="status"><span class="sr-only">Loading...</span></div></div>'
    )
    if ($("#selectedRoomSystemName").val()) {
        var availabilityDetailsUrl = "/reservations/availability-details"
        $.getJSON(
            availabilityDetailsUrl,
            {
                cluster: $("#searchCluster").val(),
                date: $("#searchDate").val(),
                systemName: $("#selectedRoomSystemName").val(),
            },
            function (data) {
                showUnitReservations(data)
            }
        )
    }
}

function switchToRoomReservationForm() {
    $("#room-reservation-input").show()
    $("#desk-reservation-input").hide()
}

function updateBookingPercentage(desksBooked, totalEnabledDesks) {
    var bookingPercentage = Math.round((desksBooked / totalEnabledDesks) * 100) + "%"
    $("#bookingPercentage").html(bookingPercentage)
}

function updateDeskAvailability(svgRoot, desks) {
    totalEnabledDesks = 0
    desksBooked = 0

    $.each(desks, function (index, desk) {
        var id = index
        var name = desk["n"]
        var systemName = desk["sn"]
        var isAvailable = desk["a"]
        var isEnabled = desk["e"]
        var isAvailableMorning = desk["am"]
        var isAvailableAfternoon = desk["aa"]
        var isOriginalSelected = desk["os"]

        if (isEnabled) {
            totalEnabledDesks++
        }
        if (!isAvailableMorning) {
            desksBooked += 0.5
        }
        if (!isAvailableAfternoon) {
            desksBooked += 0.5
        }

        $("#" + systemName, svgRoot).addClass("desk")
        if (!isEnabled) {
            $("#" + systemName, svgRoot).addClass("disabled")
        } else if (isAvailable) {
            $("#" + systemName, svgRoot).addClass("available")
        } else if (isOriginalSelected) {
            $("#" + systemName, svgRoot).addClass("original-selected")
        } else if (isAvailableMorning) {
            $("#" + systemName, svgRoot).addClass("available-morning")
        } else if (isAvailableAfternoon) {
            $("#" + systemName, svgRoot).addClass("available-afternoon")
        } else {
            $("#" + systemName, svgRoot).addClass("unavailable")
        }

        if (id == $("#selectedDeskId").val()) {
            $("#" + systemName, svgRoot).addClass("selected")
        }
        if (isEnabled) {
            $("#" + systemName, svgRoot).click(function () {
                clearSelection(svgRoot)
                $(this).addClass("selected")
                $(".selectedNameSpan").html(name)
                $("#selectedDeskSystemName").val(systemName)
                $("#selectedDeskId").val(index)
                switchToDeskReservationForm()
            })
        }
        updateBookingPercentage(desksBooked, totalEnabledDesks)
    })
}

function getDate(dateString) {
    return new Date(Date.parse(dateString))
}

function getQuartersDifference(first, second) {
    var hoursDifference = second.getHours() - first.getHours()
    var minutesDifference = second.getMinutes() - first.getMinutes()
    return Math.floor((hoursDifference * 60 + minutesDifference) / 15)
}

function popEarliest(reservations) {
    var earliest = undefined
    for (i in reservations) {
        var reservation = reservations[i]
        if (earliest == undefined || reservation["start"] < earliest["start"]) {
            earliest = reservation
        }
    }
    reservations.splice(reservations.indexOf(earliest), 1)
    return earliest
}

function addReservationBoxes(reservations, q) {
    var html = ""
    var reservation = popEarliest(reservations)
    if (reservation != undefined) {
        if (reservation["start"] != q) {
            var width = (reservation["start"] - q) / 4
            html +=
                '<div style="flex: ' +
                width +
                ' 0 0; white-space: nowrap; overflow: hidden; color: white; background-color: #0acf97; border-radius: 10rem;"><span style="padding-left: 10px; overflow: hidden;">Vrij</span></div>'
        }
        var width = (reservation["end"] - reservation["start"]) / 4
        html +=
            '<div style="flex: ' +
            width +
            ' 0 0; white-space: nowrap; overflow: hidden; color: white; background-color: #fa5c7c; border-radius: 10rem;"><span style="padding-left: 10px; overflow: hidden;">' +
            reservation["user"] +
            "</span></div>"
        html += addReservationBoxes(reservations, reservation["end"])
    } else {
        if (q != 48) {
            var width = (48 - q) / 4
            html +=
                '<div style="flex: ' +
                width +
                ' 0 0; white-space: nowrap; overflow: hidden; color: white; background-color: #0acf97; border-radius: 10rem;"><span style="padding-left: 10px; overflow: hidden;">Vrij</span></div>'
        }
    }
    return html
}

function showUnitReservations(data) {
    var html = ""

    if (data["reservations"].length == 0) {
        html +=
            '<div style="flex: 12 0 0; white-space: nowrap; overflow: hidden; color: white; background-color: #0acf97; border-radius: 10rem;"><span style="padding-left: 10px; overflow: hidden;">Vrij</span></div'
    } else {
        var sevenOClock = new Date(2020, 1, 1, 7, 0, 0, 0)
        data["reservations"].forEach(function (reservation) {
            reservation["start"] = getQuartersDifference(sevenOClock, getDate(reservation["start"]))
            reservation["end"] = getQuartersDifference(sevenOClock, getDate(reservation["end"]))
        })
        html += addReservationBoxes(data["reservations"], 0)
    }
    $("#availability-details").html(html)
}

function updateRoomAvailability(svgRoot, rooms) {
    $.each(rooms, function (index, room) {
        var id = index
        var systemName = room["sn"]
        var name = room["n"]
        var isAvailable = room["a"]
        var isEnabled = room["e"]
        var isOriginalSelected = room["os"]

        $("#" + systemName, svgRoot).addClass("room")
        if (!isEnabled) {
            $("#" + systemName, svgRoot).addClass("disabled")
        } else if (isAvailable) {
            $("#" + systemName, svgRoot).addClass("available")
        } else if (isOriginalSelected) {
            $("#" + systemName, svgRoot).addClass("original-selected")
        } else {
            $("#" + systemName, svgRoot).addClass("unavailable")
        }

        if (id == $("#selectedRoomId").val()) {
            $("#" + systemName, svgRoot).addClass("selected")
        }

        if (isEnabled) {
            $("#" + systemName, svgRoot).click(function () {
                clearSelection(svgRoot)
                $(this).addClass("selected")
                $(".selectedNameSpan").html(name)
                $("#selectedRoomSystemName").val(systemName)
                $("#selectedRoomId").val(index)
                switchToRoomReservationForm()
                refreshRoomAvailabilityDetails()
            })
        }
        refreshRoomAvailabilityDetails()
    })
}

function updateAvailability() {
    var availabilityUrl = "/reservations/availability"

    var svgDoc = document.getElementById("svgmap").contentDocument
    var svgRoot = svgDoc.documentElement

    var styleElement = svgDoc.createElementNS("http://www.w3.org/2000/svg", "style")
    styleElement.textContent =
        ".room { cursor: pointer; } .desk { cursor: pointer; } .selected { fill: #2c8ef8 !important } .original-selected { fill: #0acf97 } .disabled { fill: #d1d1d1; cursor: default !important; } .available { fill: #0acf97 } .unavailable { fill: #d23e44 } .available-morning { fill: #ffbc00 } .available-afternoon { fill: #fd7e14 }"
    svgRoot.appendChild(styleElement)

    $.getJSON(
        availabilityUrl,
        {
            cluster: $("#searchCluster").val(),
            date: $("#searchDate").val(),
            currentReservedDesk: $("#currentReservedDesk").val(),
            currentReservedRoom: $("#currentReservedRoom").val(),
        },
        function (data) {
            updateDeskAvailability(svgRoot, data["desks"])
            updateRoomAvailability(svgRoot, data["rooms"])
        }
    )
}

function clearSelection(svgRoot) {
    if (svgRoot) {
        if ($("#selectedDeskSystemName").val()) {
            $("#" + $("#selectedDeskSystemName").val(), svgRoot).removeClass("selected")
        }
        if ($("#selectedRoomSystemName").val()) {
            $("#" + $("#selectedRoomSystemName").val(), svgRoot).removeClass("selected")
        }
    }
    $("#selectedDeskSystemName").val("")
    $("#selectedDeskId").val("")
    $("#selectedRoomSystemName").val("")
    $("#selectedRoomId").val("")
    $(".selectedNameSpan").html("")
    $("#desk-reservation-input").hide()
    $("#room-reservation-input").hide()
}

function refreshMapForCluster(cluster) {
    refreshMap($("#searchCluster").val(), updateAvailability)
}

function initReserve() {
    $(".reservation-date-filter").on("change", function () {
        refreshMap($("#searchCluster").val(), updateAvailability)
    })

    $(".reservation-cluster-filter").on("change", function () {
        clearSelection()
        refreshMap($("#searchCluster").val(), updateAvailability)
    })

    if ($("#reservation-map").length) {
        refreshMap($("#searchCluster").val(), updateAvailability)
    }

    $("#desk-reservation-submit").click(function () {
        var form = $("#reservation-form")
        $("#date").val($("#searchDate").val())
        $("#when").val($("#searchWhen").val())
        $("#unitType").val("desk")
        form.submit()
    })

    $("#room-reservation-submit").click(function () {
        var form = $("#reservation-form")
        $("#date").val($("#searchDate").val())
        $("#start").val($("#searchStart").val())
        $("#end").val($("#searchEnd").val())
        $("#unitType").val("room")
        form.submit()
    })

    if ($("#unitType").val() == "desk") {
        switchToDeskReservationForm()
    } else {
        $("#desk-reservation-input").hide()
    }
    if ($("#unitType").val() == "room") {
        switchToRoomReservationForm()
        refreshRoomAvailabilityDetails()
    } else {
        $("#room-reservation-input").hide()
    }
}

function refreshClusters(callback) {
    var clustersUrl = "/reservations/clusters"
    $.getJSON(clustersUrl, function (data) {
        $("#searchCluster").html("")
        $("#searchCluster").append('<option value="">Kies...</option>')
        var selectedCluster = queryParams()["cluster"]
        $.each(data["clusters"], function (index, cluster) {
            var selectedProperty = selectedCluster == cluster["id"] ? " selected" : ""
            $("#searchCluster").append('<option value="' + cluster["id"] + '"' + selectedProperty + ">" + cluster["title"] + "</option>")
        })
        callback()
    })
}

function refreshDesksAndRooms() {
    var clusterId = $("#searchCluster").val()
    var desksUrl = "/reservations/clusters/" + clusterId + "/desks-and-rooms"
    $("#searchDesk").attr("disabled", true)
    $("#searchRoom").attr("disabled", true)
    if ($("#searchCluster").val() == "") {
        return
    }
    $.getJSON(desksUrl, function (data) {
        $("#searchDesk").html("")
        $("#searchDesk").attr("disabled", false)
        $("#searchDesk").append('<option value="">Kies...</option>')
        $("#searchRoom").html("")
        $("#searchRoom").attr("disabled", false)
        $("#searchRoom").append('<option value="">Kies...</option>')

        var selectedDesk = queryParams()["desk"]
        $.each(data["desks"], function (index, desk) {
            var selectedProperty = selectedDesk == desk["id"] ? " selected" : ""
            $("#searchDesk").append('<option value="' + desk["id"] + '"' + selectedProperty + ">" + desk["title"] + "</option>")
        })

        var selectedRoom = queryParams()["room"]
        $.each(data["rooms"], function (index, room) {
            var selectedProperty = selectedRoom == room["id"] ? " selected" : ""
            $("#searchRoom").append('<option value="' + room["id"] + '"' + selectedProperty + ">" + room["title"] + "</option>")
        })
    })
}

function refreshDepartments() {
    var departmentsUrl = "/accounts/departments"
    $.getJSON(departmentsUrl, function (data) {
        $("#searchDepartment").html("")
        $("#searchDepartment").append('<option value="">Kies...</option>')
        var selectedDepartment = queryParams()["department"]
        $.each(data["departments"], function (index, department) {
            var selectedProperty = selectedDepartment == department["id"] ? " selected" : ""
            $("#searchDepartment").append('<option value="' + department["id"] + '"' + selectedProperty + ">" + department["title"] + "</option>")
        })
    })
}

function queryParams() {
    var queryParams = {}
    if (location.search)
        location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                var s = item.split("="),
                    k = s[0],
                    v = s[1] && decodeURIComponent(s[1])
                ;(queryParams[k] = queryParams[k] || []).push(v)
            })
    return queryParams
}

function urlByIncludingParam(url, param, value) {
    url += url.indexOf("?") > -1 ? "&" : "?"
    url += param + "=" + value
    return url
}

function includeParameters(pageUrl, params) {
    Object.keys(params).forEach(function (key) {
        value = params[key]
        if (value != "" && value != null) {
            pageUrl = urlByIncludingParam(pageUrl, key, value)
        }
    })

    return pageUrl
}

function refreshPage(params) {
    var pageUrl = window.location.href.split(/[?#]/)[0]
    pageUrl = includeParameters(pageUrl, params)
    window.location.href = pageUrl
}

function submitReservationFiltersAdmin() {
    var params = {
        cluster: $("#searchCluster").val(),
        desk: $("#searchDesk").val(),
        room: $("#searchRoom").val(),
        department: $("#searchDepartment").val(),
        user: $("#searchUser").val(),
        from: $("#searchFrom").val(),
        to: $("#searchTo").val(),
        skipFullDay: $("#fullDay").prop("checked") == false,
        skipMorning: $("#morning").prop("checked") == false,
        skipAfternoon: $("#afternoon").prop("checked") == false,
        tab: $(".tab-item.active").attr("id").substring(4),
    }
    refreshPage(params)
}

function submitMyReservationsFiltersUser() {
    var params = {
        cluster: $("#searchCluster").val(),
        desk: $("#searchDesk").val(),
        room: $("#searchRoom").val(),
        department: $("#searchDepartment").val(),
        from: $("#searchFrom").val(),
        to: $("#searchTo").val(),
        skipFullDay: $("#fullDay").prop("checked") == false,
        skipMorning: $("#morning").prop("checked") == false,
        skipAfternoon: $("#afternoon").prop("checked") == false,
        tab: $(".tab-item.active").attr("id").substring(4),
    }
    refreshPage(params)
}

function submitAllReservationsFiltersUser() {
    var params = {
        date: $("#searchDate").val(),
        user: $("#searchUser").val(),
    }
    refreshPage(params)
}

function submitAccountFilters() {
    var params = {
        department: $("#searchDepartment").val(),
        user: $("#searchUser").val(),
    }
    refreshPage(params)
}

function submitEmptyFilters() {
    refreshPage({})
}

function submitManageDesksAndRoomsFiltersUser() {
    var params = {
        cluster: $("#searchCluster").val(),
        type: $("#searchUnitType").val(),
    }
    refreshPage(params)
}

function refreshTextInput(key, textInput) {
    var params = queryParams()
    var value = params[key]
    if (value != "" && value != null) {
        textInput.val(value)
    }
}

function refreshCheckbox(skipKey, checkbox) {
    var params = queryParams()
    var skipValue = params[skipKey]
    if (skipValue != "" && skipValue != null) {
        checkbox.prop("checked", skipValue == "false")
    }
}

function refreshUser() {
    refreshTextInput("user", $("#searchUser"))
}

function refreshFromTo() {
    refreshTextInput("from", $("#searchFrom"))
    refreshTextInput("to", $("#searchTo"))
}

function refreshWhen() {
    refreshCheckbox("skipFullDay", $("#fullDay"))
    refreshCheckbox("skipMorning", $("#morning"))
    refreshCheckbox("skipAfternoon", $("#afternoon"))
}

function refreshDate() {
    var params = queryParams()
    var dateParam = params["date"]
    if (dateParam != "" && dateParam != null) {
        var parts = dateParam.toString().split("-")
        var filterDate = new Date(parts[2], parts[1] - 1, parts[0])
    } else {
        var date = new Date()
        var filterDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
        if (filterDate.getDay() == 6) {
            filterDate.setDate(filterDate.getDate() + 2)
        } else if (filterDate.getDay() == 0) {
            filterDate.setDate(filterDate.getDate() + 1)
        }
    }
    $("#searchDate").datepicker("setDate", filterDate)
}

function refreshTabs() {
    var params = queryParams()
    var selectedTabId = params["tab"]
    if (!selectedTabId) {
        selectedTabId = $(".tab-default").attr("id")
    } else {
        selectedTabId = "tab-" + selectedTabId
    }
    var selectedTabItem = $("#" + selectedTabId)
    selectedTabItem.addClass("active")
}

function refreshClusterSelection() {
    var params = queryParams()
    var selectedCluster = params["cluster"]
    if (selectedCluster) {
        $("#searchCluster").val(selectedCluster)
    }
}

function refreshUnitTypeSelection() {
    var params = queryParams()
    var selectedUnitType = params["type"]
    if (selectedUnitType) {
        $("#searchUnitType").val(selectedUnitType)
    }
}

function initReservationFiltersAdmin() {
    refreshClusters(refreshDesksAndRooms)
    $("#searchCluster").on("change", function () {
        refreshDesksAndRooms()
    })
    refreshDepartments()
    refreshUser()
    refreshFromTo()
    refreshWhen()
    refreshTabs()
    $("#apply-filters").click(function () {
        submitReservationFiltersAdmin()
    })
    $("#reset-filters").click(function () {
        submitEmptyFilters()
    })
}

function initMyReservationsFiltersUser() {
    refreshClusters(refreshDesksAndRooms)
    $("#searchCluster").on("change", function () {
        refreshDesksAndRooms()
    })
    refreshDepartments()
    refreshFromTo()
    refreshWhen()
    refreshTabs()
    $("#apply-filters").click(function () {
        submitMyReservationsFiltersUser()
    })
    $("#reset-filters").click(function () {
        submitEmptyFilters()
    })
}

function initAllReservationsFiltersUser() {
    refreshDate()
    refreshUser()
    $("#apply-filters").click(function () {
        submitAllReservationsFiltersUser()
    })
    $("#reset-filters").click(function () {
        submitEmptyFilters()
    })
}

function initManageDesksAndRoomsFilters() {
    refreshClusterSelection()
    refreshUnitTypeSelection()
    $("#apply-filters").click(function () {
        submitManageDesksAndRoomsFiltersUser()
    })
}

function initAccountFilters() {
    refreshDepartments()
    refreshUser()
    refreshTabs()
    $("#apply-filters").click(function () {
        submitAccountFilters()
    })
    $("#reset-filters").click(function () {
        submitEmptyFilters()
    })
}

function openPage(page) {
    params = queryParams()
    params["page"] = page
    refreshPage(params)
}

function openTab(tab) {
    params = queryParams()
    params["tab"] = tab
    refreshPage(params)
}
